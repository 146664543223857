import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    Autocomplete,
    CircularProgress,
    TextField,
    Box,
    Avatar,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    FormControlLabel,
    Step,
    StepLabel,
    Stepper,
    ListItemButton,
    ListItemAvatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    Select,
    MenuItem,
    CardContent,
    Grid,
    CardActionArea,
    Chip,
    Divider,
    CardHeader,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { useTheme } from '@emotion/react';
import { fetchRelationshipsAndPartialities } from '../../../store/partialities/fetch';
import { selectStudents } from '../../../../store/slices/entities/students';
import { selectConcepts } from '../../../store/concepts/selectors';
import student from '../../../../assets/images/default-image-niña.svg';
import student2 from '../../../../assets/images/default-image-niño.svg';
import {
    selectCatalogConcepts,
    selectConceptsByStudent,
    selectPartialitiesByConcept,
    selectStudentsWithConceptsAndPartiality,
    selectStudentsWithPendingConcepts,
} from '../../../store/income/selectors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { CreatePaymnet } from '../../../store/income/operationSlice';
import { selectPartialities } from '../../../store/partialities/selectors';
import {
    ArrowForwardOutlined,
    CreditCardOutlined,
    GavelOutlined,
    LocalAtm,
    PaymentsOutlined,
} from '@mui/icons-material';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import useThemeColors from '../../../../theme/themes';
import {
    fetchCreatePayment,
    fetchExport,
} from '../../../store/income/fetchSlice';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DiscountIcon from '@mui/icons-material/Discount';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

const IncomeModal = ({ open, onclose }) => {
    /////////////////////SHARE STATE/////////////////////
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();
    const colors = useThemeColors();

    /////////////////////LOCAL STATE/////////////////////
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [totalSelectedAmount, setTotalSelectedAmount] = useState(0);

    const [paymentMethod, setPaymentMethod] = useState();
    const [selectedConcept, setSelectedConcept] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [stepType, setStepType] = useState(null);

    const [selectedPartialities, setSelectedPartialities] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    const paymentMethods = [
        {
            value: 1,
            label: 'Efectivo',
            bgColor: colors.velvetNight[100],
            iconColor: colors.velvetNight[500],
            icon: <PaymentIcon />,
        },
        {
            value: 2,
            label: 'Transferencia',
            bgColor: colors.sunsetBlush[100],
            iconColor: colors.sunsetBlush[500],
            icon: <AccountBalanceIcon />,
        },
        {
            value: 3,
            label: 'Tarjeta de crédito o débito',
            bgColor: colors.green[200],
            iconColor: colors.green[600],
            icon: <CreditCardIcon />,
        },
        {
            value: 4,
            label: 'Tarjeta prepaga',
            bgColor: colors.twilight[100],
            iconColor: colors.twilight[500],
            icon: <CardGiftcardIcon />,
        },
        {
            value: 5,
            label: 'Domiciliación bancaria',
            bgColor: colors.coral[100],
            iconColor: colors.coral[500],
            icon: <AccountBalanceWalletIcon />,
        },
    ];

    const steps = [
        'Seleccionar estudiante y método de pago',
        'Seleccionar conceptos',
        'Confirmar pago',
    ];

    /////////////////////USE EFFECT/////////////////////

    useEffect(() => {
        if (open) {
            dispatch(fetchCreatePayment(schoolId)).finally(() =>
                setLoading(false)
            );
        }
    }, [dispatch, schoolId, open]);

    /////////////////////SELECTORS/////////////////////
    const studentsWithPendingConcepts = useSelector(
        selectStudentsWithPendingConcepts
    );
    const concepts = useSelector((state) =>
        selectConceptsByStudent(state, selectedStudent?.student_id)
    );
    const partialities = useSelector((state) =>
        selectPartialitiesByConcept(state, selectedConcept?.concept_id)
    );

    /////////////////////HANDLERS/////////////////////

    const handleNext = (type = null) => {
        setStepType(type);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const isNextEnabled =
        activeStep === 0 ? !!selectedStudent && !!paymentMethod : true;

    const handleCheckboxChange = (partialityIndex, termIndex) => {
        setSelectedPartialities((prevState) => {
            const updatedState = { ...prevState };
            const key = `${partialityIndex}-${termIndex}`;

            // Si está seleccionado, lo deseleccionamos, de lo contrario, lo seleccionamos
            if (updatedState[key]) {
                delete updatedState[key];

                // Si se deselecciona, desmarcar todas las parcialidades posteriores
                for (
                    let tIndex = termIndex + 1;
                    tIndex < partialities[partialityIndex].remaining_terms;
                    tIndex++
                ) {
                    delete updatedState[`${partialityIndex}-${tIndex}`];
                }
            } else {
                updatedState[key] = true;
            }

            // Actualizar el valor de 'number_terms' en Formik
            const selectedCount = Object.keys(updatedState).length;
            formik.setFieldValue('number_terms', selectedCount);

            // Cálculo del total basado en las parcialidades seleccionadas
            const newTotal = partialities.reduce((acc, partiality, pIndex) => {
                const paidTerms =
                    partiality.total_terms - partiality.remaining_terms;
                const remainingPartialities = partiality.remaining_terms;

                // Calcular el monto redondeado de las parcialidades restantes
                const roundedAmounts = Array.from({
                    length: remainingPartialities - 1,
                }).map(() =>
                    Math.ceil(
                        selectedConcept.net_total / partiality.total_terms
                    )
                );

                // Calcular el total de las parcialidades redondeadas
                const totalRounded = roundedAmounts.reduce(
                    (sum, amount) => sum + amount,
                    0
                );

                // Calcular la última parcialidad ajustada
                const lastPartiality =
                    selectedConcept.net_total -
                    (paidTerms *
                        Math.ceil(
                            selectedConcept.net_total / partiality.total_terms
                        ) +
                        totalRounded);

                // Sumar los montos seleccionados
                const selectedTerms = Array.from({
                    length: remainingPartialities,
                })
                    .map((_, tIndex) => {
                        const amount =
                            tIndex < remainingPartialities - 1
                                ? roundedAmounts[tIndex]
                                : lastPartiality;
                        return updatedState[`${pIndex}-${tIndex}`] ? amount : 0;
                    })
                    .reduce((sum, amount) => sum + amount, 0);

                return acc + selectedTerms;
            }, 0);

            setTotalSelectedAmount(newTotal);

            // Gestión del estado del checkbox "Select All"
            if (!updatedState[key]) {
                setSelectAll(false);
            }

            const totalTerms = partialities.reduce(
                (acc, partiality) => acc + partiality.remaining_terms,
                0
            );
            if (selectedCount === totalTerms) {
                setSelectAll(true);
            }

            return updatedState;
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);

        if (isChecked) {
            const newSelections = {};
            let totalAmount = 0;

            partialities.forEach((partiality, partialityIndex) => {
                const remainingPartialities = partiality.remaining_terms;
                const roundedAmounts = Array.from({
                    length: remainingPartialities - 1,
                }).map(() =>
                    Math.ceil(
                        selectedConcept.net_total / partiality.total_terms
                    )
                );
                const totalRounded = roundedAmounts.reduce(
                    (sum, amount) => sum + amount,
                    0
                );
                const lastPartiality =
                    selectedConcept.net_total -
                    ((partiality.total_terms - remainingPartialities) *
                        Math.ceil(
                            selectedConcept.net_total / partiality.total_terms
                        ) +
                        totalRounded);

                Array.from({ length: remainingPartialities }).forEach(
                    (_, termIndex) => {
                        newSelections[`${partialityIndex}-${termIndex}`] = true;
                        const amount =
                            termIndex < remainingPartialities - 1
                                ? roundedAmounts[termIndex]
                                : lastPartiality;
                        totalAmount += amount;
                    }
                );
            });

            setSelectedPartialities(newSelections);
            const selectedCount = Object.keys(newSelections).length;
            formik.setFieldValue('number_terms', selectedCount);
            setTotalSelectedAmount(totalAmount);
        } else {
            setSelectedPartialities({});
            formik.setFieldValue('number_terms', 0);
            setTotalSelectedAmount(0);
        }
    };

    /////////////////////FORMIK////////////////////
    const formik = useFormik({
        initialValues: {
            user_id: null,
            concept_id: null,
            payment_method: null,
            number_terms: 0,
        },

        onSubmit: async (values) => {
            const data = {
                payment_method: values.payment_method,
                number_terms: values.number_terms,
            };

            if (values.number_terms === 0) {
                delete data.number_terms;
            }

            await dispatch(
                CreatePaymnet({
                    school_id: schoolId,
                    concept_id: values.concept_id,
                    user_id: values.user_id,
                    data: data,
                })
            )
                .unwrap()
                .then((result) => {
                    feedbackApp.showFeedback({
                        title: 'Pago registrado con exito',
                    });

                    const paymentId = result.payment[0]?.payment_id;
                    const terms =
                        values.number_terms > 0 ? values.number_terms : 1;

                    dispatch(
                        fetchExport({
                            schoolId: schoolId,
                            paymentId: paymentId,
                            terms: terms,
                        })
                    );
                    formik.resetForm();
                    setActiveStep(0);
                    onclose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                    });
                });
        },
    });

    /////////////////////RENDERS////////////////////
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onclose();
                }
            }}
            maxWidth="lg"
            fullWidth
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    padding: 5,
                    borderRadius: 2,
                },
            }}>
            {loading ? (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <DialogTitle
                        sx={{
                            textAlign: 'center',
                            paddingBottom: 5,
                        }}>
                        <Typography variant="h4" component="div">
                            Registrar un nuevo pago
                        </Typography>
                        <Typography variant="body2">
                            Aquí podrás registrar un nuevo pago
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                setActiveStep(0);
                                formik.resetForm();
                                onclose();
                            }}
                            sx={{
                                position: 'absolute',
                                right: 4,
                                top: 4,
                                color: (theme) => theme.palette.grey[500],
                            }}>
                            <CloseIcon />
                        </IconButton>
                        <Stepper
                            sx={{ mt: 5 }}
                            activeStep={activeStep}
                            alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </DialogTitle>
                    <formik>
                        <DialogContent>
                            {activeStep === 0 && (
                                <>
                                    <Box sx={{ mt: 2 }}>
                                        <Autocomplete
                                            options={
                                                studentsWithPendingConcepts
                                            }
                                            noOptionsText="No hay opciones disponibles"
                                            getOptionLabel={(option) =>
                                                `${option.name} ${option.last_name} ${option.second_last_name}`
                                            }
                                            value={
                                                studentsWithPendingConcepts.find(
                                                    (student) =>
                                                        student.student_id ===
                                                        formik.values.user_id
                                                ) || null
                                            }
                                            onChange={(event, newValue) => {
                                                setSelectedStudent(newValue);
                                                formik.setFieldValue(
                                                    'user_id',
                                                    newValue?.student_id
                                                );
                                            }}
                                            renderOption={(props, option) => {
                                                const defaultAvatar =
                                                    option.gender === 'M'
                                                        ? student
                                                        : student2;
                                                const avatarSrc =
                                                    option.url_photo_profile ||
                                                    defaultAvatar;

                                                return (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            mb: 1,
                                                            p: 1,
                                                            borderRadius: 1,
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    theme
                                                                        .palette
                                                                        .action
                                                                        .hover,
                                                            },
                                                        }}>
                                                        <Avatar
                                                            sx={{
                                                                width: 56,
                                                                height: 56,
                                                                mr: 2,
                                                            }}
                                                            src={avatarSrc}
                                                            alt={option.name}
                                                        />
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {option.name}{' '}
                                                                {
                                                                    option.last_name
                                                                }{' '}
                                                                {
                                                                    option.second_last_name
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary">
                                                                ID:{' '}
                                                                {
                                                                    option.student_id
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary">
                                                                Teléfono:{' '}
                                                                {
                                                                    option.cellphone
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Seleccionar estudiante"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        <Box mt={3}>
                                            <Autocomplete
                                                noOptionsText="No hay opciones disponibles"
                                                options={paymentMethods}
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                value={
                                                    paymentMethods.find(
                                                        (method) =>
                                                            method.value ===
                                                            formik.values
                                                                .payment_method
                                                    ) || null
                                                }
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setPaymentMethod(
                                                            newValue
                                                        );
                                                        formik.setFieldValue(
                                                            'payment_method',
                                                            newValue.value
                                                        );
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Seleccionar método de pago"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            p: 1,
                                                        }}
                                                        {...props}>
                                                        <Box
                                                            sx={{
                                                                backgroundColor:
                                                                    option.bgColor,
                                                                color: option.iconColor,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                width: 32,
                                                                height: 32,
                                                            }}>
                                                            {option.icon}
                                                        </Box>
                                                        <Typography
                                                            sx={{ ml: 2 }}>
                                                            {option.label}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        pt={3}>
                                        <Button
                                            disabled={!isNextEnabled}
                                            onClick={handleNext}>
                                            Siguiente
                                        </Button>
                                    </Box>
                                </>
                            )}

                            {activeStep === 1 && selectedStudent && (
                                <>
                                    <Box sx={{ flexGrow: 1, mt: 3 }}>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignContent="center">
                                            {concepts.map((concept) => (
                                                <Grid
                                                    item
                                                    key={concept.concept_id}
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                    lg={4}>
                                                    <Card
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            height: '100%',
                                                            boxShadow: 3,
                                                            borderRadius: 2,
                                                        }}>
                                                        <CardContent
                                                            sx={{
                                                                flexGrow: 1,
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                justifyContent:
                                                                    'center',
                                                                textAlign:
                                                                    'center',
                                                                padding: 3,
                                                            }}>
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    mb: 1,
                                                                }}>
                                                                {
                                                                    concept
                                                                        .catalogConcept
                                                                        .concept
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                color="textSecondary"
                                                                sx={{ mb: 2 }}>
                                                                {
                                                                    concept.description
                                                                }
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    alignItems:
                                                                        'flex-start',
                                                                    paddingTop: 10,
                                                                }}>
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        width: '100%',
                                                                    }}>
                                                                    <Box
                                                                        sx={{
                                                                            borderRadius:
                                                                                '100%',
                                                                            height: 25,
                                                                            width: 25,
                                                                            display:
                                                                                'flex',
                                                                            justifyContent:
                                                                                'center',
                                                                            alignItems:
                                                                                'center',
                                                                            backgroundColor:
                                                                                colors
                                                                                    .red[200],
                                                                        }}>
                                                                        <AttachMoneyIcon
                                                                            fontSize="small"
                                                                            sx={{
                                                                                color: colors
                                                                                    .red[600],
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            width: '100%',
                                                                            justifyContent:
                                                                                'space-between',
                                                                        }}>
                                                                        <Typography
                                                                            sx={{
                                                                                ml: 1,
                                                                            }}
                                                                            variant="body2">
                                                                            Recargo:
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body2"
                                                                            sx={{
                                                                                color: colors
                                                                                    .red[500],
                                                                            }}>
                                                                            + $
                                                                            {
                                                                                concept.surcharge_total
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        width: '100%',
                                                                        paddingTop: 1,
                                                                    }}>
                                                                    <Box
                                                                        sx={{
                                                                            borderRadius:
                                                                                '100%',
                                                                            height: 25,
                                                                            width: 25,
                                                                            display:
                                                                                'flex',
                                                                            justifyContent:
                                                                                'center',
                                                                            alignItems:
                                                                                'center',
                                                                            backgroundColor:
                                                                                colors
                                                                                    .green[200],
                                                                        }}>
                                                                        <DiscountIcon
                                                                            fontSize="small"
                                                                            sx={{
                                                                                color: colors
                                                                                    .green[600],
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            width: '100%',
                                                                            justifyContent:
                                                                                'space-between',
                                                                        }}>
                                                                        <Typography
                                                                            sx={{
                                                                                ml: 1,
                                                                            }}
                                                                            variant="body2">
                                                                            Descuento:
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body2"
                                                                            sx={{
                                                                                color: colors
                                                                                    .green[500],
                                                                            }}>
                                                                            - $
                                                                            {
                                                                                concept.discount_total
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box>
                                                                    <Divider variant="middle" />
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        width: '100%',
                                                                        paddingTop: 1,
                                                                    }}>
                                                                    <Box
                                                                        sx={{
                                                                            borderRadius:
                                                                                '100%',
                                                                            height: 25,
                                                                            width: 25,
                                                                            display:
                                                                                'flex',
                                                                            justifyContent:
                                                                                'center',
                                                                            alignItems:
                                                                                'center',
                                                                            backgroundColor:
                                                                                colors
                                                                                    .teal[200],
                                                                        }}>
                                                                        <LocalAtm
                                                                            fontSize="small"
                                                                            sx={{
                                                                                color: colors
                                                                                    .teal[600],
                                                                            }}
                                                                        />
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            width: '100%',
                                                                            justifyContent:
                                                                                'space-between',
                                                                        }}>
                                                                        <Typography
                                                                            sx={{
                                                                                ml: 1,
                                                                            }}
                                                                            variant="body2">
                                                                            Total:
                                                                        </Typography>
                                                                        <Typography variant="body2">
                                                                            $
                                                                            {
                                                                                concept.remaining
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </CardContent>
                                                        {concept.in_agreement ===
                                                        true ? (
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                endIcon={
                                                                    <NavigateNextOutlinedIcon />
                                                                }
                                                                onClick={() => {
                                                                    setSelectedConcept(
                                                                        concept
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'concept_id',
                                                                        concept.concept_id
                                                                    );
                                                                    handleNext(
                                                                        'Parcialidades'
                                                                    );
                                                                }}
                                                                sx={{
                                                                    borderTopLeftRadius: 0,
                                                                    borderTopRightRadius: 0,
                                                                    mt: 'auto',
                                                                }}>
                                                                Ver
                                                                parcialidades
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                endIcon={
                                                                    <NavigateNextOutlinedIcon />
                                                                }
                                                                onClick={() => {
                                                                    setSelectedConcept(
                                                                        concept
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'concept_id',
                                                                        concept.concept_id
                                                                    );
                                                                    handleNext(
                                                                        'Pago'
                                                                    );
                                                                }}
                                                                sx={{
                                                                    borderTopLeftRadius: 0,
                                                                    borderTopRightRadius: 0,
                                                                    mt: 'auto',
                                                                }}>
                                                                Pagar Concepto
                                                            </Button>
                                                        )}
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>

                                        <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            pt={3}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}>
                                                Atrás
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            )}
                            <Box>
                                {activeStep === 2 && selectedConcept && (
                                    <Box sx={{ mt: 3 }}>
                                        {activeStep === 2 &&
                                            stepType === 'Parcialidades' &&
                                            selectedConcept && (
                                                <Accordion expanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={false}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                flexGrow: 1,
                                                            }}>
                                                            <GavelOutlined />
                                                            <Typography
                                                                sx={{ ml: 1 }}>
                                                                {
                                                                    selectedConcept
                                                                        .catalogConcept
                                                                        .concept
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Chip
                                                            Filled
                                                            color="primary"
                                                            sx={{
                                                                borderRadius: 10,
                                                                color: 'white',
                                                            }}
                                                            label={partialities.reduce(
                                                                (
                                                                    acc,
                                                                    partiality
                                                                ) =>
                                                                    acc +
                                                                    partiality.remaining_terms,
                                                                0
                                                            )}
                                                        />
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Box sx={{ mb: 2 }}>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                }}>
                                                                <Checkbox
                                                                    checked={
                                                                        selectAll
                                                                    }
                                                                    onChange={
                                                                        handleSelectAllChange
                                                                    }
                                                                    sx={{
                                                                        color: 'red',
                                                                        '&.Mui-checked':
                                                                            {
                                                                                color: 'red',
                                                                            },
                                                                    }}
                                                                />
                                                                <Typography>
                                                                    Pagar todas
                                                                    las
                                                                    parcialidades
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        {partialities.map(
                                                            (
                                                                partiality,
                                                                partialityIndex
                                                            ) => {
                                                                const paidTerms =
                                                                    partiality.total_terms -
                                                                    partiality.remaining_terms;
                                                                const termAmount =
                                                                    selectedConcept.net_total /
                                                                    partiality.total_terms;
                                                                const remainingPartialities =
                                                                    partiality.remaining_terms;

                                                                const roundedAmounts =
                                                                    Array.from({
                                                                        length:
                                                                            remainingPartialities -
                                                                            1,
                                                                    }).map(() =>
                                                                        Math.ceil(
                                                                            termAmount
                                                                        )
                                                                    );

                                                                const totalRounded =
                                                                    roundedAmounts.reduce(
                                                                        (
                                                                            acc,
                                                                            amount
                                                                        ) =>
                                                                            acc +
                                                                            amount,
                                                                        0
                                                                    );

                                                                const lastPartiality =
                                                                    selectedConcept.net_total -
                                                                    (paidTerms *
                                                                        Math.ceil(
                                                                            termAmount
                                                                        ) +
                                                                        totalRounded);

                                                                return (
                                                                    <Box
                                                                        key={
                                                                            partialityIndex
                                                                        }
                                                                        sx={{
                                                                            justifyContent:
                                                                                'space-between',
                                                                            paddingLeft: 2,
                                                                        }}>
                                                                        {Array.from(
                                                                            {
                                                                                length: remainingPartialities,
                                                                            }
                                                                        ).map(
                                                                            (
                                                                                _,
                                                                                termIndex
                                                                            ) => {
                                                                                const currentTerm =
                                                                                    paidTerms +
                                                                                    termIndex +
                                                                                    1;

                                                                                const amount =
                                                                                    termIndex <
                                                                                    remainingPartialities -
                                                                                        1
                                                                                        ? roundedAmounts[
                                                                                              termIndex
                                                                                          ]
                                                                                        : lastPartiality;

                                                                                const isPreviousSelected =
                                                                                    termIndex ===
                                                                                        0 ||
                                                                                    selectedPartialities[
                                                                                        `${partialityIndex}-${
                                                                                            termIndex -
                                                                                            1
                                                                                        }`
                                                                                    ];

                                                                                return (
                                                                                    <Box
                                                                                        key={
                                                                                            termIndex
                                                                                        }
                                                                                        sx={{
                                                                                            display:
                                                                                                'flex',
                                                                                            alignItems:
                                                                                                'center',
                                                                                        }}>
                                                                                        <Checkbox
                                                                                            checked={
                                                                                                selectedPartialities[
                                                                                                    `${partialityIndex}-${termIndex}`
                                                                                                ] ||
                                                                                                false
                                                                                            }
                                                                                            onChange={() =>
                                                                                                handleCheckboxChange(
                                                                                                    partialityIndex,
                                                                                                    termIndex
                                                                                                )
                                                                                            }
                                                                                            disabled={
                                                                                                !isPreviousSelected
                                                                                            }
                                                                                        />
                                                                                        <Box
                                                                                            sx={{
                                                                                                display:
                                                                                                    'flex',
                                                                                                justifyContent:
                                                                                                    'space-between',
                                                                                                alignItems:
                                                                                                    'center',
                                                                                                width: '100%',
                                                                                            }}>
                                                                                            <Typography>
                                                                                                Parcialidad{' '}
                                                                                                {
                                                                                                    currentTerm
                                                                                                }
                                                                                            </Typography>
                                                                                            <Typography variant="caption">
                                                                                                Total:
                                                                                                $
                                                                                                {amount.toFixed(
                                                                                                    2
                                                                                                )}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Box>
                                                                );
                                                            }
                                                        )}
                                                        <Divider
                                                            sx={{
                                                                mt: 2,
                                                                mb: 2,
                                                            }}
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between',
                                                                alignItems:
                                                                    'center',
                                                            }}>
                                                            <Typography variant="h6">
                                                                Total a pagar:
                                                            </Typography>
                                                            <Chip
                                                                label={`$${totalSelectedAmount.toFixed(
                                                                    2
                                                                )}`}
                                                                color="primary"
                                                                sx={{
                                                                    color: 'white',
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            />
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        {stepType === 'Pago' && (
                                            <Card
                                                sx={{
                                                    mt: 2,
                                                    p: 2,
                                                    boxShadow: 3,
                                                }}>
                                                <CardHeader
                                                    title="Resumen del Pago"
                                                    sx={{
                                                        textAlign: 'center',
                                                        backgroundColor:
                                                            '#f5f5f5',
                                                        borderRadius: '4px',
                                                    }}
                                                />
                                                <CardContent>
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}>
                                                            <Avatar
                                                                alt={
                                                                    selectedStudent.name
                                                                }
                                                                src={
                                                                    selectedStudent.photoUrl ||
                                                                    (selectedStudent.gender ===
                                                                    'M'
                                                                        ? student
                                                                        : student2)
                                                                }
                                                                sx={{
                                                                    width: 56,
                                                                    height: 56,
                                                                    mr: 2,
                                                                }}
                                                            />
                                                            <Typography
                                                                variant="h6"
                                                                component="div">
                                                                {
                                                                    selectedStudent.name
                                                                }{' '}
                                                                {
                                                                    selectedStudent.last_name
                                                                }{' '}
                                                                {
                                                                    selectedStudent.second_last_name
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider
                                                                sx={{ my: 2 }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box sx={{ mb: 2 }}>
                                                                <Typography
                                                                    variant="h6"
                                                                    component="div"
                                                                    sx={{
                                                                        pb: 1,
                                                                        mb: 2,

                                                                        fontWeight:
                                                                            'bold',
                                                                    }}>
                                                                    Concepto:
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    component="li">
                                                                    {
                                                                        selectedConcept
                                                                            .catalogConcept
                                                                            .concept
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box sx={{ mb: 2 }}>
                                                                <Typography
                                                                    variant="h6"
                                                                    component="div"
                                                                    sx={{
                                                                        pb: 1,
                                                                        mb: 2,

                                                                        fontWeight:
                                                                            'bold',
                                                                    }}>
                                                                    Descripción:
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    component="li">
                                                                    {
                                                                        selectedConcept.description
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider
                                                                sx={{ my: 2 }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                }}>
                                                                <Box
                                                                    sx={{
                                                                        borderRadius:
                                                                            '100%',
                                                                        height: 25,
                                                                        width: 25,
                                                                        display:
                                                                            'flex',
                                                                        justifyContent:
                                                                            'center',
                                                                        alignItems:
                                                                            'center',
                                                                        backgroundColor:
                                                                            colors
                                                                                .red[200],
                                                                        mr: 1,
                                                                    }}>
                                                                    <DiscountIcon
                                                                        fontSize="small"
                                                                        sx={{
                                                                            color: colors
                                                                                .red[600],
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Typography
                                                                    variant="h6"
                                                                    component="div"
                                                                    sx={{
                                                                        flexGrow: 1,
                                                                    }}>
                                                                    Recargo:
                                                                </Typography>
                                                                <Typography
                                                                    variant="h5"
                                                                    color="textSecondary">
                                                                    <Box
                                                                        component="span"
                                                                        sx={{
                                                                            fontWeight:
                                                                                'bold',

                                                                            color: colors
                                                                                .red[600],
                                                                        }}>
                                                                        +{' '}
                                                                    </Box>
                                                                    $
                                                                    {
                                                                        selectedConcept.surcharge_total
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                }}>
                                                                <Box
                                                                    sx={{
                                                                        borderRadius:
                                                                            '100%',
                                                                        height: 25,
                                                                        width: 25,
                                                                        display:
                                                                            'flex',
                                                                        justifyContent:
                                                                            'center',
                                                                        alignItems:
                                                                            'center',
                                                                        backgroundColor:
                                                                            colors
                                                                                .green[200],
                                                                        mr: 1,
                                                                    }}>
                                                                    <DiscountIcon
                                                                        fontSize="small"
                                                                        sx={{
                                                                            color: colors
                                                                                .green[600],
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Typography
                                                                    variant="h6"
                                                                    component="div"
                                                                    sx={{
                                                                        flexGrow: 1,
                                                                    }}>
                                                                    Descuento:
                                                                </Typography>
                                                                <Typography
                                                                    variant="h5"
                                                                    color="textSecondary"
                                                                    sx={{
                                                                        alignItems:
                                                                            'center',
                                                                    }}>
                                                                    <Box
                                                                        component="span"
                                                                        sx={{
                                                                            fontWeight:
                                                                                'bold',
                                                                            color: colors
                                                                                .green[600],
                                                                        }}>
                                                                        -{' '}
                                                                    </Box>
                                                                    $
                                                                    {
                                                                        selectedConcept.discount_total
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider
                                                                sx={{ my: 2 }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}>
                                                            <Box
                                                                sx={{
                                                                    borderRadius:
                                                                        '100%',
                                                                    height: 25,
                                                                    width: 25,
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    backgroundColor:
                                                                        colors
                                                                            .teal[200],
                                                                }}>
                                                                <LocalAtm
                                                                    fontSize="small"
                                                                    sx={{
                                                                        color: colors
                                                                            .teal[600],
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Typography
                                                                variant="h6"
                                                                component="div"
                                                                sx={{
                                                                    flexGrow: 1,
                                                                }}>
                                                                Total:
                                                            </Typography>
                                                            <Typography
                                                                variant="h4"
                                                                color="textSecondary"
                                                                sx={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}>
                                                                $
                                                                {
                                                                    selectedConcept.net_total
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        )}
                                        <DialogActions
                                            sx={{
                                                paddingTop: 5,
                                                paddingBottom: 0,
                                            }}>
                                            <Button
                                                fullWidth
                                                disabled={activeStep === 0}
                                                onClick={handleBack}>
                                                Atrás
                                            </Button>
                                            {stepType === 'Parcialidades' ? (
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    color="primary"
                                                    onClick={
                                                        formik.handleSubmit
                                                    }
                                                    disabled={
                                                        formik.isSubmitting ||
                                                        !formik.values
                                                            .number_terms
                                                    }>
                                                    {formik.isSubmitting ? (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    ) : (
                                                        'Pagar'
                                                    )}
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    color="primary"
                                                    onClick={
                                                        formik.handleSubmit
                                                    }
                                                    disabled={
                                                        formik.isSubmitting ||
                                                        !formik.isValid ||
                                                        !formik.dirty
                                                    }>
                                                    {formik.isSubmitting ? (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    ) : (
                                                        'Pagar'
                                                    )}
                                                </Button>
                                            )}
                                        </DialogActions>
                                    </Box>
                                )}
                            </Box>
                        </DialogContent>
                    </formik>
                </>
            )}
        </Dialog>
    );
};

export default IncomeModal;
