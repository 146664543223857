import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Divider, Tooltip, Grid, Stack, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import LoadingButton from '@mui/lab/LoadingButton';

const TabsComponent = ({
    currentTab,
    onTabChange,
    invalidateUI,
    statusServer,
}) => {
    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },

                flexGrow: 1,
            }}>
            <>
                <Grid container spacing={3} sx={{ mb: 4 }}>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Tabs
                            value={currentTab}
                            onChange={onTabChange}
                            aria-label="tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            sx={{ width: '100%' }}>
                            <Tab
                                label="Inicio"
                                value="inicio"
                                component={Link}
                                to="/Finanzas/inicio"
                            />
                            <Tab
                                label="Pagos"
                                value="pagos"
                                component={Link}
                                to="/Finanzas/pagos"
                            />
                            <Tab
                                label="Convenios"
                                value="convenios"
                                component={Link}
                                to="/Finanzas/convenios"
                            />
                            <Tab
                                label="Conceptos"
                                value="conceptos"
                                component={Link}
                                to="/Finanzas/conceptos"
                            />
                            <Tab
                                label="Descuentos"
                                value="descuentos"
                                component={Link}
                                to="/Finanzas/descuentos"
                            />
                            <Tab
                                label="Recargos"
                                value="recargos"
                                component={Link}
                                to="/Finanzas/recargos"
                            />
                        </Tabs>
                        <Divider textAlign="left" />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Stack
                            spacing={2}
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent="flex-end"
                            alignItems="center">
                            <Tooltip title="Sincronizar información">
                                <LoadingButton
                                    onClick={invalidateUI}
                                    loadingPosition="start"
                                    variant="contained"
                                    size="small"
                                    startIcon={<SyncIcon />}
                                    loading={statusServer === 'pending'}>
                                    Sincronizar
                                </LoadingButton>
                            </Tooltip>
                        </Stack>
                    </Grid>
                </Grid>
            </>
        </Box>
    );
};

export default TabsComponent;
