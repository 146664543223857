import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Tooltip,
    Zoom,
    Avatar,
    Divider,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectLastStudentsWithPayments } from '../../../store/home/tables';
import femaleAvatar from '../../../assets/img/female_student.png';
import maleAvatar from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';

import { NewFeatures } from '../../../components/features/NewFeatures';

const TopTransactionsCard = () => {
    const lastStudentsWithPayments = useSelector(
        selectLastStudentsWithPayments
    ).value;

    const rows = Array.isArray(lastStudentsWithPayments)
        ? lastStudentsWithPayments.slice(0, 5)
        : [];

    const getAvatarSrc = (row) => {
        if (row.avatar) {
            return row.avatar;
        }
        return row.gener === 'H'
            ? maleAvatar
            : row.gener === 'M'
            ? femaleAvatar
            : defaultStudent;
    };

    return (
        <Card
            sx={{
                width: '100%',
                height: '100%',
                padding: '10px',
            }}>
            <CardContent>
                <Box position="relative">
                    <Card
                        sx={{
                            backgroundColor: '#4169e2',
                            color: 'white',
                            borderRadius: '15px',
                            width: '100%',
                            height: '200px',
                            padding: '10px',
                        }}
                    />
                    <Box
                        sx={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 5,
                        }}>
                        <Typography
                            variant="h3"
                            component="div"
                            style={{
                                marginTop: '20px',
                                fontWeight: '800',
                                color: '#464646',
                                letterSpacing: '2px',
                            }}>
                            $4,5090.99
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: '#464646',
                                fontWeight: 100,
                            }}>
                            Total Balance
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 5,
                            padding: '10px',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: 5,
                            }}>
                            <Box
                                sx={{
                                    backgroundColor: '#6383e6',
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '100px',
                                    mr: 2,
                                    width: '30px',
                                    height: '30px',
                                }}
                            />
                            <Typography variant="subtitle1" component="div">
                                Saldo Restante
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: 10,
                            }}>
                            <Box
                                sx={{
                                    backgroundColor: '#6383e6',
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '100px',
                                    mr: 2,
                                    width: '30px',
                                    height: '30px',
                                }}
                            />
                            <Typography variant="subtitle1" component="div">
                                Mas
                            </Typography>
                        </Box>
                    </Box>
                    <NewFeatures />
                </Box>

                <Box
                    sx={{
                        alignItems: 'center',
                        mt: 5,
                        padding: '10px',
                        width: '100%',
                    }}>
                    <Box>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{
                                fontWeight: '800',
                                color: '#464646',
                                letterSpacing: '1px',
                                paddingTop: '40px',
                            }}>
                            Ultimos Pagos
                        </Typography>
                        <Box>
                            {rows.map((row, index) => (
                                <div key={index}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mt: 3,
                                            borderRadius: '10px',
                                            padding: '10px',
                                        }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '400px',
                                                mr: 2,
                                                width: '40px',
                                                height: '40px',
                                            }}>
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                title={row.label}
                                                arrow>
                                                <Avatar
                                                    src={getAvatarSrc(row)}
                                                    sx={{
                                                        width: '50px',
                                                        height: '50px',
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}>
                                            <Box>
                                                <Typography
                                                    variant="body2"
                                                    component="div"
                                                    style={{
                                                        fontWeight: '700',
                                                        color: '#000000',
                                                    }}>
                                                    {row.concept}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    component="div"
                                                    style={{
                                                        fontWeight: '100',
                                                        color: '#000000',
                                                    }}>
                                                    {row.date}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 'auto',
                                            }}>
                                            <Typography
                                                style={{
                                                    fontWeight: '700',
                                                    color: '#08bb0e',
                                                }}>
                                                + ${row.value}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                </div>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default TopTransactionsCard;
