import React, { useEffect, useState } from 'react';
import TabsComponent from '../../components/tabs';
import { Backdrop, Box, CircularProgress, Skeleton } from '@mui/material';
import SurchargesTable from './Components/SurchargesTable';
import {
    invalidate,
    loadUI,
    selectStatusServer,
} from '../../store/surcharges/fetchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import SkeletonTable from '../../components/skeletons/skeletonTable';
import { Error } from '../../../components/Feedback';
import ErrorPage from '../../components/Error/ErrorPage';

const Surcharges = () => {
    //////////////////// SHARE STATE ////////////////////
    const dispatch = useDispatch();
    const Auth = useAuth();
    const history = useHistory();
    const feedbackApp = useFeedback();
    const statusServer = useSelector(selectStatusServer);

    //////////////////// LOCAL STATE ////////////////////
    const [currentTab, setCurrentTab] = useState('recargos');

    //////////////////// HANDLERS ////////////////////
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    //////////////////// USEEFECT ////////////////////

    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id));
    }, [history]);

    //////////////////// FUNCTIONS ////////////////////

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    return (
        <>
            <TabsComponent
                currentTab={currentTab}
                onTabChange={handleTabChange}
                invalidateUI={invalidateUI}
                statusServer={statusServer}
            />
            <Box
                sx={{
                    paddingLeft: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                    paddingRight: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                    paddingBottom: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                }}>
                {statusServer == 'pending' && (
                    <SkeletonTable width="100%" height={500} />
                )}

                {statusServer == 'rejected' && (
                    <ErrorPage
                        onRetry={reload}
                        maxWidth={'30rem'}
                        mt={20}
                        alingContent={'center'}
                        message={
                            'Ocurrió un error mientras intentábamos acceder a este recurso ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                        }
                    />
                )}

                {statusServer == 'fulfilled' && (
                    <>
                        <SurchargesTable />
                    </>
                )}
            </Box>
        </>
    );
};

export default Surcharges;
