import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentIncome } from './selectors';
import { upsertManyPayments } from '../../../store/slices/entities/payments/income';
import { upsertManyUsers } from '../../../store/slices/entities/users';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';
import { upsertManyPartialities } from '../../../store/slices/entities/payments/partialities';
import { upsertManyAgreements } from '../../../store/slices/entities/payments/agreements';
import Services from '../../../service/Connection';
import { emptyState } from '../emptyState';

////////////////////////////////////// SLICE //////////////////////////////////////
const IncomeSlice = createSlice({
    name: 'incomeUI/Server',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */

        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;

            state.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.statusServer = 'rejected';
            state.feedback = action.payload.feedback;
        });
    },
});

export const { invalidate } = IncomeSlice.actions;

export default IncomeSlice.reducer;

////////////////////////////////////// THUNKS // ////////////////////////////////////
/**
 * Cargar informacion de la UI de Pagos
 */
export const loadUI = createAsyncThunk(
    'IncomeUI/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const payments = await Services.getPaymentsBySchool(schoolId).then(
                (res) => res.data.data
            );

            const concepts = await Services.getConceptsBySchool(schoolId).then(
                (res) => res.data.data
            );

            const Catalog_concepts = await Services.getSchoolsByConceptsCatalog(
                schoolId
            ).then((res) => res.data.data);

            const students = await Services.StudentsBySchool(schoolId).then(
                (res) => res.data.data
            );

            const agreements = await Services.getAgreementBySchool(
                schoolId
            ).then((res) => res.data.data);

            thunkAPI.dispatch(upsertManyPayments(payments));
            thunkAPI.dispatch(upsertManyCataloConcepts(Catalog_concepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyAgreements(agreements));

            return { payments, concepts, Catalog_concepts, students };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },

    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectIncomeFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const fetchExport = createAsyncThunk(
    'Income/fetchExport',
    async ({ schoolId, paymentId, terms }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const fetchTiket = await Connection.getExportable(
                'payment-bill',
                'pdf',
                {
                    payment_id: paymentId,
                    school_id: schoolId,
                    number_terms: terms,
                }
            );

            const url = window.URL.createObjectURL(new Blob([fetchTiket.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `payment_${paymentId}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return { success: true };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * fetcha para la creación de un concepto
 */

export const fetchCreatePayment = createAsyncThunk(
    'Income/fetch/create',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [
                responseConcepts,
                responseStudents,
                responseAgreements,
                responsePartialities,
            ] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.StudentsBySchool(schoolId),
                Connection.getAgreementBySchool(schoolId),
                Connection.getPartialitiesBySchool(schoolId),
            ]);

            const concepts = responseConcepts.data.data;
            const students = responseStudents.data.data;
            const agreements = responseAgreements.data.data;
            const partialities = responsePartialities.data.data;

            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyAgreements(agreements));
            thunkAPI.dispatch(upsertManyPartialities(partialities));

            return { concepts, students, agreements };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

export const NewfetchCreatePayment = createAsyncThunk(
    'Income/fetch/create',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [responseConcepts, responseStudents, responseAgreements] =
                await Promise.all([
                    Connection.getConceptsBySchool(schoolId),
                    Connection.StudentsBySchool(schoolId),
                    Connection.getAgreementBySchool(schoolId),
                    Connection.getPartialitiesBySchool(schoolId),
                ]);

            const concepts = responseConcepts.data.data;
            const students = responseStudents.data.data;
            const agreements = responseAgreements.data.data;

            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyAgreements(agreements));

            return { concepts, students, agreements };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// //////////////////////////////////// SELECTORES // ////////////////////////////////////

/**
 * Selector para recuperar el estado de la petición de los pagos
 */
export const selectIncomeFetch = createSelector(
    selectPaymentIncome,
    (state) => state.fetch
);

/**
 * Selector para recuperar el estado del servidor
 */

export const selectStatusServer = createSelector(
    selectPaymentIncome,
    (state) => state.fetch.statusServer
);
