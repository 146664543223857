import { Box, Card, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { TopStudentsDiscount } from './Components/TopStudentsDiscount';
import TabsComponent from '../../components/tabs';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth, useFeedback } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import ErrorPage from '../../components/Error/ErrorPage';
import {
    invalidate_lastStudentWithDiscount,
    selectCardStatus_Discount_Student,
    loadUILastStudentWithDiscountCard,
} from '../../store/home/cards';
import WelcomeCard from './Components/WelcomeCard';
import GainsCard from './Components/GainsCard';
import ToPayCard from './Components/ToPayCard';
import {
    invalidate_Concepts_Without_Payments,
    invalidate_Total_Discounts_Lost,
    invalidate_Total_Gains,
    loadUIConceptsWithoutPayment,
    loadUITotalDiscountLost,
    loadUITotalGains,
    selectKpiStatus_Concepts_Without_Payment,
    selectKpiStatus_Total_Discounts_Lost,
    selectKpiStatus_Total_Gains,
} from '../../store/home/kpis';
import { Col, Layout, Row } from 'antd';
import TopTransactionsCard from './Components/TopTransactions';
import DiscountConceptsCard from './Components/DiscountConceptsCard';
import DebtorTable from './Components/DebtorTable';
import {
    invalidate_last_student_with_discount,
    invalidate_last_students_with_payments,
    invalidate_monthly_payments_count,
    loadUILastStudentsWithPayments,
    LoadUILastStudentWithDiscountTable,
    loadUIMonthlyPaymentsCount,
    selectTableStatus_last_student_with_discount_table,
    selectTableStatus_last_students_with_payments,
    selectTableStatus_monthly_payments_count,
} from '../../store/home/tables';
import {
    invalidate_Concepts_With_Discount,
    loadUIConceptsWithDiscount,
    selectChartsStatus_Concepts_With_Discount,
} from '../../store/home/charts';
import {
    invalidate_Top_Debtors,
    loadUITopDebtors,
    selectTopStatus_Top_Debtors,
} from '../../store/home/tops';

const DashboardPayments = () => {
    //////////////////// SHARE STATE ////////////////////
    const dispatch = useDispatch();
    const Auth = useAuth();
    const history = useHistory();
    const feedbackApp = useFeedback();

    //////////////////// SHARE STATE ////////////////////
    /*
     * Cards
     */
    const statusServer_Last_Student_With_Discount_card = useSelector(
        selectCardStatus_Discount_Student
    );
    /*
     * KPIs
     */
    const StatusServer_Total_Discounts_Lost = useSelector(
        selectKpiStatus_Total_Discounts_Lost
    );
    const StatusServer_Total_Gains = useSelector(selectKpiStatus_Total_Gains);

    const StatusServer_Concepts_Without_Payment = useSelector(
        selectKpiStatus_Concepts_Without_Payment
    );

    /*
     * Tablas
     */
    const StatusServer_last_student_with_discount_table = useSelector(
        selectTableStatus_last_student_with_discount_table
    );

    const StatusServer_last_students_with_payments = useSelector(
        selectTableStatus_last_students_with_payments
    );

    const StatusServer_monthly_payments_count = useSelector(
        selectTableStatus_monthly_payments_count
    );

    /**
     * charts
     */
    const StatusServer_Concepts_With_Discount = useSelector(
        selectChartsStatus_Concepts_With_Discount
    );

    /**
     * tops
     */
    const StatusServer_Top_Debtors = useSelector(selectTopStatus_Top_Debtors);

    const getCombinedStatus = (statuses) => {
        if (statuses.some((status) => status === 'pending')) {
            return 'pending';
        }
        if (statuses.every((status) => status === 'fulfilled')) {
            return 'fulfilled';
        }
        return 'rejected';
    };

    const combinedStatus = getCombinedStatus([
        // Cards
        statusServer_Last_Student_With_Discount_card,

        // KPIs
        StatusServer_Total_Discounts_Lost,
        StatusServer_Total_Gains,
        StatusServer_Concepts_Without_Payment,

        // Tablas
        StatusServer_last_student_with_discount_table,
        StatusServer_last_students_with_payments,
        StatusServer_monthly_payments_count,

        // charts
        StatusServer_Concepts_With_Discount,

        // tops
        StatusServer_Top_Debtors,
    ]);

    //////////////////// LOCAL STATE ////////////////////
    const [currentTab, setCurrentTab] = useState('inicio');

    //////////////////// HANDLERS ////////////////////
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };
    //////////////////// USEEFECT ////////////////////

    useEffect(() => {
        //////////////////// Cards ////////////////////
        dispatch(loadUILastStudentWithDiscountCard(Auth.getUser().school_id));

        //////////////////// KPIs ////////////////////
        dispatch(loadUITotalDiscountLost(Auth.getUser().school_id));
        dispatch(loadUIConceptsWithoutPayment(Auth.getUser().school_id));
        dispatch(loadUITotalGains(Auth.getUser().school_id));

        //////////////////// Tablas ////////////////////
        dispatch(LoadUILastStudentWithDiscountTable(Auth.getUser().school_id));
        dispatch(loadUILastStudentsWithPayments(Auth.getUser().school_id));
        dispatch(loadUIMonthlyPaymentsCount(Auth.getUser().school_id));

        //////////////////// Charts ////////////////////
        dispatch(loadUIConceptsWithDiscount(Auth.getUser().school_id));

        //////////////////// Tops ////////////////////
        dispatch(loadUITopDebtors(Auth.getUser().school_id));
    }, [history]);

    //////////////////// FUNCTIONS ////////////////////

    /**
     * Funcion para recargar los datos del usuario
     */

    const reload = () => {
        //////////////////// Cards ////////////////////
        dispatch(loadUILastStudentWithDiscountCard(Auth.getUser().school_id));

        //////////////////// KPIs ////////////////////
        dispatch(loadUITotalDiscountLost(Auth.getUser().school_id));
        dispatch(loadUIConceptsWithoutPayment(Auth.getUser().school_id));
        dispatch(loadUITotalGains(Auth.getUser().school_id));

        //////////////////// Tablas ////////////////////
        dispatch(LoadUILastStudentWithDiscountTable(Auth.getUser().school_id));
        dispatch(loadUILastStudentsWithPayments(Auth.getUser().school_id));
        dispatch(loadUIMonthlyPaymentsCount(Auth.getUser().school_id));

        //////////////////// Charts ////////////////////
        dispatch(loadUIConceptsWithDiscount(Auth.getUser().school_id));

        //////////////////// Tops ////////////////////
        dispatch(loadUITopDebtors(Auth.getUser().school_id));
    };

    ////// Cards /////

    const reloadloadUILastStudentWithDiscountCard = () => {
        dispatch(loadUILastStudentWithDiscountCard(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    ////// KPIs /////

    const reloadloadUITotalDiscountLost = () => {
        dispatch(loadUITotalDiscountLost(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUIConceptsWithoutPayment = () => {
        dispatch(loadUIConceptsWithoutPayment(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUITotalGains = () => {
        dispatch(loadUITotalGains(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };
    ////// tablas /////

    const reloadLoadUILastStudentWithDiscountTable = () => {
        dispatch(LoadUILastStudentWithDiscountTable(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUILastStudentsWithPayments = () => {
        dispatch(loadUILastStudentsWithPayments(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUIMonthlyPaymentsCount = () => {
        dispatch(loadUIMonthlyPaymentsCount(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    ////// charts /////
    const reloadloadUIConceptsWithDiscount = () => {
        dispatch(loadUIConceptsWithDiscount(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    ////// tops /////
    const reloadloadUITopDebtors = () => {
        dispatch(loadUITopDebtors(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        /// Cards
        dispatch(invalidate_lastStudentWithDiscount());

        /// KPIs
        dispatch(invalidate_Concepts_Without_Payments());
        dispatch(invalidate_Total_Discounts_Lost());
        dispatch(invalidate_Total_Gains());

        /// tablas
        dispatch(invalidate_last_student_with_discount());
        dispatch(invalidate_last_students_with_payments());
        dispatch(invalidate_monthly_payments_count());

        /// charts
        dispatch(invalidate_Concepts_With_Discount());

        /// tops
        dispatch(invalidate_Top_Debtors());
        reload();
    };

    return (
        <>
            <TabsComponent
                currentTab={currentTab}
                onTabChange={handleTabChange}
                invalidateUI={invalidateUI}
                statusServer={combinedStatus}
            />

            <Box
                sx={{
                    paddingLeft: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                    paddingRight: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                    paddingBottom: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                }}>
                <Row gutter={[32, 24]}>
                    <Col xs={24} xl={24}>
                        <WelcomeCard statusServer={combinedStatus} />
                    </Col>
                    <Col xs={24} xl={16}>
                        <Row gutter={[32, 24]}>
                            <Col xs={24} xl={12}>
                                {StatusServer_Total_Gains === 'pending' && (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={200}
                                        sx={{
                                            mb: 2,
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    />
                                )}
                                {StatusServer_Total_Gains === 'rejected' && (
                                    <ErrorPage
                                        onRetry={reloadloadUITotalGains}
                                        maxWidth={'100%'}
                                        message={
                                            'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                        }
                                    />
                                )}
                                {StatusServer_Total_Gains === 'fulfilled' && (
                                    <GainsCard
                                        statusServer={
                                            StatusServer_monthly_payments_count
                                        }
                                        onClickRetry={
                                            reloadloadUIMonthlyPaymentsCount
                                        }
                                    />
                                )}
                            </Col>
                            <Col xs={24} xl={12}>
                                {StatusServer_Concepts_Without_Payment ===
                                    'pending' && (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={200}
                                        sx={{
                                            mb: 2,
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    />
                                )}
                                {StatusServer_Concepts_Without_Payment ===
                                    'rejected' && (
                                    <ErrorPage
                                        onRetry={
                                            reloadloadUIConceptsWithoutPayment
                                        }
                                        maxWidth={'100%'}
                                        message={
                                            'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                        }
                                    />
                                )}
                                {StatusServer_Concepts_Without_Payment ===
                                    'fulfilled' && <ToPayCard />}
                            </Col>
                        </Row>
                        <Row
                            gutter={[32, 24]}
                            style={{
                                paddingTop: '20px',
                                paddingBottom: '20px',
                            }}>
                            <Col xs={24} xl={24}>
                                {StatusServer_Concepts_With_Discount ===
                                    'pending' && (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={200}
                                        sx={{
                                            mb: 2,
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    />
                                )}
                                {StatusServer_Concepts_With_Discount ===
                                    'rejected' && (
                                    <ErrorPage
                                        onRetry={
                                            reloadloadUIConceptsWithDiscount
                                        }
                                        maxWidth={'100%'}
                                        message={
                                            'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                        }
                                    />
                                )}
                                {StatusServer_Concepts_With_Discount ===
                                    'fulfilled' && <DiscountConceptsCard />}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} xl={8}>
                        {StatusServer_last_students_with_payments ===
                            'pending' && (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={500}
                                sx={{
                                    mb: 2,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                }}
                            />
                        )}

                        {StatusServer_last_students_with_payments ===
                            'rejected' && (
                            <ErrorPage
                                onRetry={reloadloadUILastStudentsWithPayments}
                                maxWidth="100%"
                                message={
                                    'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                }
                            />
                        )}

                        {StatusServer_last_students_with_payments ===
                            'fulfilled' && <TopTransactionsCard />}
                    </Col>
                </Row>
                <Row
                    gutter={[32, 24]}
                    style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <Col xs={24} xl={8}>
                        {StatusServer_last_student_with_discount_table ===
                            'pending' && (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={200}
                                sx={{
                                    mb: 2,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                }}
                            />
                        )}
                        {StatusServer_last_student_with_discount_table ===
                            'rejected' && (
                            <ErrorPage
                                onRetry={
                                    reloadLoadUILastStudentWithDiscountTable
                                }
                                maxWidth={'100%'}
                                message={
                                    'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                }
                            />
                        )}
                        {StatusServer_last_student_with_discount_table ===
                            'fulfilled' && <TopStudentsDiscount />}
                    </Col>{' '}
                    <Col xs={24} xl={16}>
                        {StatusServer_Top_Debtors === 'pending' && (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={200}
                                sx={{
                                    mb: 2,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                }}
                            />
                        )}
                        {StatusServer_Top_Debtors === 'rejected' && (
                            <ErrorPage
                                onRetry={reloadloadUITopDebtors}
                                maxWidth={'100%'}
                                message={
                                    'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                }
                            />
                        )}
                        {StatusServer_Top_Debtors === 'fulfilled' && (
                            <DebtorTable />
                        )}
                    </Col>
                </Row>
                <Row gutter={[32, 24]}></Row>
            </Box>
        </>
    );
};

export default DashboardPayments;
