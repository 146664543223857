import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, CardActions, CardContent, Stack } from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import DnsIcon from '@mui/icons-material/Dns';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import PersonIcon from '@mui/icons-material/Person';
import { selectOneSettingBySchoolId } from '../../../../../store/slices/entities/settings';

import { DateTime } from 'luxon';
import {
    newStoreCalificationList,
    selectClassroomScoresDetails,
    selectClassroomViewmodelScoreOperationtatusById,
} from '../../../../store/meGroups/classrooms';
import moment from 'moment';
import { round } from '../../../../../helper';
import CalificationMenu from './CalificationMenu';
import _ from 'lodash';

/**
 * Componente que muestra un un formulario para crear
 * o actualziar calificaciones de un grupo
 *
 * @param {*} param0
 *
 * @returns
 */
const SubjectScoresTable = ({ uuid, schoolId, groupId, subjectId, onSave }) => {
    moment.locale('es');

    ///////// HOOKS////////

    const dispatch = useDispatch();

    ///////// SHARED STATE ////////

    const scoreMin = useSelector(
        selectOneSettingBySchoolId(schoolId, 'calification-minimum')
    );

    const recoveryDays = useSelector(
        selectOneSettingBySchoolId(schoolId, 'recovery-days')
    );

    const testData = useSelector(
        selectClassroomScoresDetails(subjectId, groupId)
    );

    const statusOperation = useSelector(
        selectClassroomViewmodelScoreOperationtatusById(uuid)
    );

    const { students, califications, partials } = testData;

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [hasRegularization, setHasRegularization] = useState(false);
    const [invalidScore, setInvalidScore] = useState(false);

    const updateStatus = {
        1: {
            color: 'grey',
            text: 'Profesor',
            icon: <PersonIcon />,
            class: 'left-status-onhold',
        },
        2: {
            color: '#ffbf43',
            text: 'Administrador',
            icon: <LaptopChromebookIcon />,
            class: 'left-status-processing',
        },
        3: {
            color: '#0a90d3',
            text: 'PIE',
            icon: <DnsIcon />,
            class: 'left-status-imported',
        },
    };

    const currentDate = DateTime.local().toFormat('yyyy-MM-dd');

    /*
     * UseEffect que da formato a la data
     * de calificaciones, conectando materias, estudiantes y calificaciones
     */
    useEffect(() => {
        if (statusOperation === 'idle' || statusOperation === 'fulfilled') {
            let studentItems = students
                .map((student) => {
                    let partialsAdditionalProp = {};
                    let partialsItems = {};

                    let regularizationsAdditionalProp = {};
                    let regularizationsItems = {};

                    let totalOverage = 0;

                    let total = 0;
                    let calificationsNum = 0;

                    let weightedValuesSum = 0;

                    let hasWeighing = partials.some(
                        (partial) => partial.weighing > 0
                    );

                    let totalWeighing = partials.reduce(
                        (sum, partial) => sum + (partial.weighing || 0),
                        0
                    );

                    let remainingWeighing = 100 - totalWeighing;

                    let zeroWeighingScoreSum = 0;
                    let zeroWeighingCount = 0;

                    let regTotal = 0;
                    let regCalificationsNum = 0;

                    let lastApprovedValue = 0;

                    for (let partial of partials) {
                        if (partial.is_regularized === false) {
                            let caliBySubject = califications.find(
                                (d) =>
                                    d.student_id == student.student_id &&
                                    d.partial_id == partial.partial_id
                            );

                            let cal = 0;

                            if (caliBySubject?.calification) {
                                total += caliBySubject.calification;
                                cal = caliBySubject.calification;
                                ++calificationsNum;

                                if (hasWeighing && partial.weighing === 0) {
                                    zeroWeighingScoreSum +=
                                        caliBySubject.calification;
                                    zeroWeighingCount++;
                                }
                            }

                            let partialName = `p-${partial.partial_id}`;

                            let limitDate = moment(partial.limit_date);
                            limitDate.add(recoveryDays.value, 'days');
                            let limitDateFormatted = limitDate.format(
                                'YYYY-MM-DD hh:mm:ss'
                            );

                            partialsAdditionalProp[partialName] = {
                                value: caliBySubject?.calification
                                    ? caliBySubject.calification
                                    : 0,
                                description: caliBySubject?.description || '',
                                in_recovery:
                                    caliBySubject?.in_recovery || false,
                                add_description: '',
                                created_by: caliBySubject?.created_by,
                                updated_by: caliBySubject?.updated_by,
                            };

                            partialsItems[partialName] = {
                                partial: partial,
                                score: caliBySubject,
                                value: cal,
                                isExists: caliBySubject !== undefined,
                                partialActive:
                                    currentDate >= partial.start_date &&
                                    currentDate <= limitDateFormatted,
                                isValid: true,
                            };

                            let average = 0;

                            if (hasWeighing) {
                                let zeroWeighingAverage =
                                    zeroWeighingCount > 0
                                        ? zeroWeighingScoreSum /
                                          zeroWeighingCount
                                        : 0;

                                let remainingWeighingScore =
                                    zeroWeighingAverage *
                                    (remainingWeighing / 100);

                                let weightedScoreSum = 0;
                                partials.forEach((partial) => {
                                    if (partial.weighing > 0) {
                                        let score = _.find(califications, {
                                            student_id: student.student_id,
                                            partial_id: partial.partial_id,
                                        });

                                        weightedScoreSum +=
                                            (score?.calification || 0) *
                                            (partial.weighing / 100);
                                    }
                                });

                                average =
                                    remainingWeighingScore + weightedScoreSum;
                            } else {
                                average =
                                    total > 0 ? total / calificationsNum : 0;
                            }

                            totalOverage = round(average, 1);

                            partialsAdditionalProp['total'] = totalOverage;
                        } else {
                            let caliBySubject = califications.find(
                                (d) =>
                                    d.student_id == student.student_id &&
                                    d.partial_id == partial.partial_id
                            );

                            let calreg = 0;

                            if (caliBySubject?.calification) {
                                regTotal += caliBySubject.calification;
                                calreg = caliBySubject.calification;
                                ++regCalificationsNum;

                                if (
                                    caliBySubject.calification >=
                                    scoreMin?.value
                                ) {
                                    lastApprovedValue = calreg;
                                }
                            }

                            let regularizationName = `r-${partial.partial_id}`;

                            regularizationsAdditionalProp[regularizationName] =
                                {
                                    value: caliBySubject?.calification
                                        ? caliBySubject.calification
                                        : 0,
                                    description:
                                        caliBySubject?.description || '',
                                    in_recovery: false,
                                    add_description: '',
                                    created_by: caliBySubject?.created_by,
                                    updated_by: caliBySubject?.updated_by,
                                };

                            regularizationsItems[regularizationName] = {
                                regularization: partial,
                                score: caliBySubject,
                                value: {
                                    value: calreg,
                                    description:
                                        caliBySubject?.description || '',
                                    in_recovery: false,
                                },
                                isExists: caliBySubject !== undefined,
                                description:
                                    caliBySubject !== undefined
                                        ? caliBySubject.description
                                        : '',
                                partialActive:
                                    currentDate >= partial.start_date &&
                                    currentDate <= partial.limit_date,
                                isValid: true,
                            };

                            let totalreg;

                            if (lastApprovedValue <= scoreMin?.value) {
                                totalreg = totalOverage;
                            } else {
                                totalreg = Math.max(
                                    totalOverage,
                                    lastApprovedValue
                                );
                            }

                            regularizationsAdditionalProp['totalreg'] =
                                totalreg;

                            const hasRegularization = Object.keys(
                                regularizationsItems
                            ).some((key) => {
                                const regularizationItem =
                                    regularizationsItems[key];
                                return (
                                    Object.keys(regularizationsItems).length >
                                        0 &&
                                    regularizationItem.partialActive === true
                                );
                            });

                            setHasRegularization(hasRegularization);
                        }
                    }

                    return {
                        ...student,
                        ...partialsAdditionalProp,
                        ...regularizationsAdditionalProp,
                        regularizationsItems,
                        partialsItems,
                    };
                })
                .sort((a, b) => {
                    const lastNameA = a.last_name;
                    const lastNameB = b.last_name;
                    return lastNameA.localeCompare(lastNameB);
                });

            setRows(studentItems);
        }
    }, [subjectId, statusOperation]);

    /**
     * Columnas del datagrid
     */
    useEffect(() => {
        let columnsItems = [
            {
                column: 'student',
                title: 'ALUMNOS',
                sticky: true,
                className: '',
            },
        ];
        const partialColumns = partials
            .filter((partial) => !partial.is_regularized)
            .map((partial) => ({
                column: `p-${partial.partial_id}`,
                title: `P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            }));

        columnsItems = [...columnsItems, ...partialColumns];
        columnsItems.push({
            column: `total`,
            title: `PROMEDIO`,
            sticky: false,
            className: 'parcial-column',
        });

        const regularizedColumns = partials
            .filter((partial) => partial.is_regularized)
            .map((partial) => ({
                column: `r-${partial.partial_id}`,
                title: `R${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            }));

        columnsItems = [...columnsItems, ...regularizedColumns];

        columnsItems.push({
            column: `totalreg`,
            title: `FINAL`,
            sticky: false,
            className: 'parcial-column',
        });

        //////////////////// FORMATO DE LAS COLUMNAS /////////////////////

        let columns = columnsItems.map((i) => {
            return {
                field: i.column,
                headerName: i.title,
                flex: 0.4,
                minWidth: i.column === 'student' ? 155 : 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                headerClassName: i.partialActive ? 'partial-active--cell' : '',
                cellClassName: (params) => {
                    let row =
                        params.row.partialsItems[params.field]?.partialActive;
                    if (row) {
                        return 'partial-active--cell';
                    }

                    return '';
                },
                valueGetter: (params) => {
                    if (params.field === 'student') {
                        return `${params.row.last_name || ''} ${
                            params.row.second_last_name || ''
                        } ${params.row.name || ''}`.replace('  ', ' ');
                    }

                    if (
                        params.field === 'total' ||
                        params.field === 'totalreg'
                    ) {
                        return params.value;
                    }
                    return params.value.value;
                },
                renderCell: (params) => {
                    if (params.field === 'total') {
                        return params.value;
                    }

                    if (params.field === 'totalreg') {
                        return params.value;
                    }

                    if (params.field === 'student') {
                        const FullName = `${params.row.last_name || ''} ${
                            params.row.second_last_name || ''
                        } ${params.row.name || ''}`;
                        return (
                            <div
                                style={{
                                    whiteSpace: 'pre-line',
                                    textAlign: 'center',
                                }}
                            >
                                {FullName}
                            </div>
                        );
                    }

                    let partial = params.row[params.field];

                    let isInput = false;
                    let isRegularizationActive = false;

                    if (!hasRegularization) {
                        isInput =
                            params.row.partialsItems[params.field]
                                ?.partialActive === true;
                    } else {
                        isInput =
                            params.row.regularizationsItems[params.field]
                                ?.partialActive === true &&
                            params.row.total <= scoreMin?.value &&
                            params.row.totalreg <= scoreMin?.value;

                        isRegularizationActive =
                            params.row.regularizationsItems[params.field]
                                ?.partialActive === true;
                    }

                    const shouldEnableInputs =
                        !params.row.regularizationsItems[params.field]
                            ?.isExists ||
                        (params.row.total === scoreMin?.value &&
                            params.row.totalreg === scoreMin?.value);

                    return (
                        <CalificationMenu
                            value={partial.value}
                            description={partial.description}
                            in_recovery={partial.in_recovery}
                            studentId={params.row.student_id}
                            add_description={partial.add_description}
                            updated_by={partial.updated_by}
                            created_by={partial.created_by}
                            column={params.field}
                            onChange={isInput ? handleInputChange : undefined}
                            scoreMin={scoreMin}
                            disabled={
                                !isInput ||
                                (hasRegularization && !shouldEnableInputs)
                            }
                        />
                    );
                },
            };
        });

        const studentColumnIndex = columns.findIndex(
            (column) => column.field === 'student'
        );
        if (studentColumnIndex !== -1) {
            columns[studentColumnIndex].maxWidth = 300;
        }

        setColumns(columns);
    }, [subjectId, rows, statusOperation]);

    /**
     * Actualizacion de los inputs del menu de calificaciones
     *
     * @param integer studentId Id del alumno que representa el id del row
     * @param string partialColumnName Nombre de la columna
     */
    const handleInputChange =
        ({ studentId, column, isCheckbox, isComment }) =>
        (e) => {
            if (e.key === 'r' || e.key === 'c') {
                e.preventDefault();
                return;
            }
            const value = e.target.value;
            const checked = e.target.checked;

            let regex = /^(100|(\d{1,2})(\.\d{1,2})?)?$/;

            let nextRows = rows.map((row) => {
                const isPartialItem = column.startsWith('p-');
                const isRegularizationItem = column.startsWith('r-');

                const updatedRow = { ...row };

                if (studentId === row.student_id) {
                    if (isCheckbox) {
                        updatedRow[column] = {
                            ...updatedRow[column],
                            in_recovery: checked,
                            changed: true,
                        };
                    } else if (!isComment) {
                        updatedRow[column] = {
                            ...updatedRow[column],
                            value: value,
                            changed: true,
                        };
                        if (Number(value) > 10 || isNaN(value)) {
                            setInvalidScore(true);
                        } else {
                            setInvalidScore(false);
                        }
                    } else {
                        updatedRow[column] = {
                            ...updatedRow[column],
                            add_description: value,
                            changed: true,
                        };
                    }
                }

                if (isPartialItem || isRegularizationItem) {
                    updatedRow[column] = {
                        ...updatedRow[column],
                        isValid: regex.test(updatedRow[column].value),
                    };
                }

                return updatedRow;
            });

            setRows(nextRows);
        };

    /**
     * Registra las calificaciones
     */
    const handleSaveCalificationList = () => {
        let calificationNotProceced = [];
        for (let student of rows) {
            for (const property in student) {
                if (/p-[0-9]+/.test(property)) {
                    const {
                        value,
                        description,
                        in_recovery,
                        add_description,
                        ...partialWithoutValue
                    } = student[property];
                    calificationNotProceced.push({
                        value: value,
                        description: `${description} ${add_description}`,
                        in_recovery: in_recovery,
                        student: student,
                        isExists: student.partialsItems[property].isExists,
                        score: student.partialsItems[property].score,
                        partial: student.partialsItems[property].partial,
                        changed: student.partialsItems[property].changed,
                        ...partialWithoutValue,
                    });
                } else if (/r-[0-9]+/.test(property)) {
                    const {
                        value,
                        description,
                        in_recovery,
                        add_description,
                        ...regularizationWithoutValue
                    } = student[property];
                    calificationNotProceced.push({
                        value: value,
                        description: `${description} ${add_description}`,
                        in_recovery: in_recovery,
                        student: student,
                        isExists:
                            student.regularizationsItems[property].isExists,
                        score: student.regularizationsItems[property].score,
                        regularization:
                            student.regularizationsItems[property]
                                .regularization,
                        ...regularizationWithoutValue,
                    });
                }
            }
        }

        let califications = calificationNotProceced.filter((item) => {
            return (
                item.value != 0 &&
                item.value != '' &&
                item.value != null &&
                item.value <= 10 &&
                item.changed === true
            );
        });

        const updateRequest = califications
            .filter((item) => item.isExists)
            .map((item) => ({
                calification: item.value,
                calification_id: item.score.calification_id,
                description: item.description,
                in_recovery: item.in_recovery,
            }));

        const createRequest = califications
            .filter((item) => !item.isExists)
            .map((item) => ({
                is_final: 0,
                calification: item.value,
                partial_id: item.partial
                    ? item.partial.partial_id
                    : item.regularization.partial_id,
                student_id: item.student.student_id,
                subject_id: subjectId,
                description: item.description,
                in_recovery: item.in_recovery,
            }));

        dispatch(
            newStoreCalificationList({
                subjectId,
                update: updateRequest,
                create: createRequest,
                uuid: uuid,
            })
        )
            .unwrap()
            .then((data) => {
                onSave('done', data);
            })
            .catch((err) => {
                onSave('err', err);
            });
    };

    return (
        <Card
            sx={{
                pt: 0,
                pl: 0,
                pr: 0,
                pb: 0,
                ' & .MuiDataGrid-root': {
                    border: 'none',
                },
            }}
        >
            <CardContent>
                <Box
                    sx={{
                        '& .partial-active--cell': {
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            color: '#1a3e72',
                            fontWeight: '600',
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row.student_id}
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: () => (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <GridToolbar />
                                </div>
                            ),
                        }}
                        componentsProps={{
                            panel: {
                                placement: 'bottom-end',
                            },
                        }}
                        rowsPerPageOptions={[]}
                        disableSelectionOnClick
                        autoHeight
                        disableDensitySelector
                    />
                </Box>
            </CardContent>
            <CardActions
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Stack
                    direction={'row'}
                    spacing={0.5}
                    justifyContent="center"
                    alignItems="center"
                >
                    {Object.values(updateStatus).map((status) => {
                        return (
                            <Stack
                                sx={{ color: status.color }}
                                direction={'row'}
                                spacing={0.5}
                                justifyContent="center"
                                alignItems="center"
                            >
                                {status.icon} {status.text}
                            </Stack>
                        );
                    })}
                </Stack>
                <LoadingButton
                    size="small"
                    endIcon={<SendIcon />}
                    loading={statusOperation === 'pending'}
                    loadingPosition="end"
                    variant="contained"
                    onClick={handleSaveCalificationList}
                    disabled={invalidScore}
                >
                    Guardar
                </LoadingButton>
            </CardActions>
        </Card>
    );
};

export default SubjectScoresTable;
