import React from 'react';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

const SkeletonTable = ({ width, height }) => {
    return (
        <Box sx={{ mb: 2 }}>
            <Skeleton
                variant="rectangular"
                width={width}
                height={height}
                animation="wave"
                sx={{
                    bgcolor: 'grey.200',
                    borderRadius: 2,
                }}
            />
        </Box>
    );
};

export default SkeletonTable;
