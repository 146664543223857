import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { Row, Col } from 'antd';

import BarChart from '../../../assets/icons/bar-chart-svgrepo-com.svg';

import { selectTotalDiscountLoss } from '../../../store/home/charts';
import { ChartTooltip } from '../../../components/Tooltips/ChartTooltips';
import { ReactComponent as MinusWallet } from '../../../assets/icons/wallet-minus-svgrepo-com.svg';

const DiscountConceptsCard = () => {
    const conceptsWithDiscount = useSelector(selectTotalDiscountLoss).value;

    const totalDiscounts = conceptsWithDiscount.reduce(
        (sum, concept) => sum + concept.total_discount_applied,
        0
    );

    const data = conceptsWithDiscount.map((concept, index) => ({
        id: concept.catalog,
        label: concept.catalog,
        value: concept.total_discount_applied,
        percentage: (
            (concept.total_discount_applied / totalDiscounts) *
            100
        ).toFixed(2),
        color: getRandomColor(index),
        concepts_count: concept.concepts_count,
    }));

    function getRandomColor(index) {
        const colors = ['#0099ff', '#ffcc66', '#99cc00', '#66ccff', '#ff6666'];
        return colors[index % colors.length];
    }

    const stats = data.map((concept) => ({
        label: concept.label,
        value: `$${concept.value}`,
        color: concept.color,
    }));

    const sortedStats = stats.sort((a, b) => {
        const percentageA = parseFloat(a.value);
        const percentageB = parseFloat(b.value);
        return percentageB - percentageA;
    });

    const totalConcepts = conceptsWithDiscount.reduce(
        (sum, concept) => sum + concept.concepts_count,
        0
    );
    const [displayedConceptsCount, setDisplayedConceptsCount] =
        useState(totalConcepts);

    return (
        <>
            <Card
                sx={{
                    borderRadius: '12px',
                    width: '100%',
                    height: '105%',
                    padding: '10px',
                }}>
                <CardContent>
                    <Row gutter={[32, 24]}>
                        <Col xs={24} xl={24}>
                            <Typography
                                variant="h6"
                                component="div"
                                style={{
                                    fontWeight: '800',
                                    color: '#464646',
                                    letterSpacing: '1px',
                                }}>
                                Descuentos por concepto de venta
                            </Typography>
                        </Col>
                    </Row>
                    <Row gutter={[32, 24]}>
                        <Col xs={24} xl={12}>
                            <div style={{ height: 300 }}>
                                <ResponsivePie
                                    data={data}
                                    innerRadius={0.6}
                                    padAngle={8}
                                    cornerRadius={1}
                                    activeOuterRadiusOffset={8}
                                    margin={{ top: 50, bottom: 20, left: 0 }}
                                    colors={{ datum: 'data.color' }}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [['brighter', '2.6']],
                                    }}
                                    radialLabelsSkipAngle={10}
                                    radialLabelsTextColor="#333333"
                                    radialLabelsLinkColor={{
                                        from: 'color',
                                        modifiers: [['darker', 1.2]],
                                    }}
                                    sliceLabelsSkipAngle={10}
                                    enableArcLinkLabels={false}
                                    arcLinkLabel={(d) => `${d.id}`}
                                    tooltip={({ datum }) => {
                                        setDisplayedConceptsCount(
                                            datum.data.concepts_count
                                        );
                                        return (
                                            <ChartTooltip
                                                id={datum.id}
                                                value={`${datum.data.percentage}%`}
                                                color={datum.color}
                                                indexValue={datum.label}
                                            />
                                        );
                                    }}
                                    arcLinkLabelsSkipAngle={10}
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: 'color' }}
                                    enableArcLabels={false}
                                    arcLabelsSkipAngle={1}
                                    arcLabelsTextColor={{
                                        from: 'color',
                                        modifiers: [['darker', 2]],
                                    }}
                                    valueFormat=" >-"
                                    startAngle={-180}
                                    sortByValue={true}
                                    onMouseLeave={() =>
                                        setDisplayedConceptsCount(totalConcepts)
                                    }
                                />
                                <Box height={100} position="relative">
                                    <Box
                                        position="absolute"
                                        top="-135%"
                                        left="50%"
                                        style={{
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                        textAlign="center">
                                        <Typography
                                            variant="h4"
                                            component="div">
                                            {displayedConceptsCount}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            Descuentos
                                        </Typography>
                                    </Box>
                                </Box>
                            </div>
                        </Col>
                        <Col xs={24} xl={12}>
                            <Box display="flex" mb={2} mt={5}>
                                <Box
                                    style={{
                                        backgroundColor: '#e3e8fe',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '10px',
                                        paddingBottom: '10px',
                                        borderRadius: '10px',
                                    }}>
                                    <MinusWallet
                                        style={{ width: 50, height: 50 }}
                                    />
                                </Box>
                                <Box ml={1}>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        style={{ marginTop: '10px' }}>
                                        Total de descuentos aplicados
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        component="div"
                                        style={{
                                            fontWeight: 'bold',
                                        }}>
                                        ${totalDiscounts}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Row
                                gutter={[32, 24]}
                                style={{
                                    justifyContent: 'center',
                                    margin: '0%',
                                    padding: '0%',
                                }}>
                                {sortedStats.map((stat, index) => (
                                    <Col
                                        xs={8}
                                        xl={12}
                                        key={index}
                                        style={{ padding: '0' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '10px',
                                                marginBottom: '10px',
                                                color: '#050505',
                                                borderRadius: '5px',
                                                width: '100%',
                                            }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'center',
                                                    marginLeft: '35px',
                                                }}>
                                                <Box
                                                    position="absolute"
                                                    top="35%"
                                                    left="2%"
                                                    style={{
                                                        transform:
                                                            'translate(-50%, -50%)',
                                                    }}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        width: '40px',
                                                        height: '40px',
                                                        backgroundColor:
                                                            stat.color,

                                                        borderRadius: '50%',
                                                        mr: 1,
                                                    }}>
                                                    <img
                                                        src={BarChart}
                                                        alt="Error"
                                                        width={30}
                                                        height={30}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        component="div"
                                                        variant="subtitle1"
                                                        sx={{
                                                            fontSize: '10px',
                                                        }}>
                                                        {stat.label}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '15px',
                                                        }}>
                                                        {stat.value}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
        </>
    );
};

export default DiscountConceptsCard;
