import React from 'react';

import { Box, Typography } from '@mui/material';

////////////////////////// ICONS //////////////////////////
import BuildIcon from '@mui/icons-material/Build';

export const NewFeatures = () => {
    return (
        <Box display={'flex'}>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '113%',
                    height: '117%',
                    backgroundColor: '#ffffff2f',
                    backdropFilter: 'blur(10px)',
                    zIndex: 0,
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    left: '-26px',
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    padding: '20px',
                    borderRadius: '15px',
                    zIndex: 1,
                    pointerEvents: 'none',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                    textAlign: 'center',
                    width: '100%',
                }}>
                {/* Icon */}
                <BuildIcon
                    style={{
                        fontSize: '50px',
                        color: '#ff4e78',
                        marginBottom: '10px',
                    }}
                />
                <Typography
                    variant="h5"
                    style={{
                        fontWeight: '600',
                        color: '#464646',
                        marginBottom: '10px',
                    }}>
                    Estamos creando nuevas funcionalidades para ti
                </Typography>
                <Typography
                    variant="body1"
                    style={{
                        color: '#888888',
                    }}>
                    ¡Muy pronto tendrás acceso a esta sección!
                </Typography>
            </div>
        </Box>
    );
};
