import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Box,
    Skeleton,
    Stack,
    Avatar,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import * as Style from '../../../styles/index';
import { getLevelName } from '../../../libs/utils';
import {
    getProfessorsSubject,
    selectProfessorsSubject,
} from '../../../store/slices/usersUI';

const SubjectModal = ({ open, setOpen, professorId }) => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const classes = Style.tableModalStyle();

    const professorSubject = useSelector((state) =>
        selectProfessorsSubject(state, professorId)
    );
    const loading = useSelector(
        (state) => state.usersUI.server.statusOperation === 'pending'
    );

    const config = useSelector(selectSettingBySchoolId(schoolId));
    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    useEffect(() => {
        if (open && !professorSubject.length && !loading) {
            dispatch(getProfessorsSubject(professorId));
        }
    }, [open, dispatch, professorId]);

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        setOpen(false);
    };

    const CustomToolbar = (props) => {
        let titleBarBackground = config.find(
            (res) => res.key === 'theme-color-title-bar'
        );
        let fontColor = config.find((res) => res.key === 'theme-color-font');
        return (
            <GridToolbarContainer
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <GridToolbarColumnsButton
                    style={{
                        color: fontColor.value,
                    }}
                />
                <GridToolbarFilterButton
                    style={{
                        color: fontColor.value,
                    }}
                />
                <GridToolbarExport
                    style={{
                        color: fontColor.value,
                    }}
                    csvOptions={{
                        fields: columns.map((column) => column.field),
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'folio',
            headerName: 'Folio',
            flex: 0.1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'title',
            headerName: 'Nombre de la materia',
            flex: 0.4,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'level',
            headerName: 'Nivel',
            flex: 0.2,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{getLevelName(params.row.level)}</Typography>
                );
            },
        },
    ];

    const rows = professorSubject.map((subject) => {
        return {
            id: subject.catalog_subject_id,
            folio: subject.folio,
            title: subject.title,
            level: subject.level,
        };
    });

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            classes={{ paper: classes.dialogWrapper }}
            onClose={handleClose}
        >
            <DialogTitle
                className={classes.dialogTitle}
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <Typography>Materias</Typography>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            backgroundColor: titleBarBackground.value,
                            color: fontColor.value,
                        },
                        '& .MuiDataGrid-root': {
                            border: 0,
                        },
                    }}
                >
                    <Box style={{ textAlign: 'center' }}>
                        {loading ? (
                            <Stack spacing={2} sx={{ padding: 2 }}>
                                <Skeleton variant="rectangular" height={70} />
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                            </Stack>
                        ) : (
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[]}
                                disableSelectionOnClick
                                disableDensitySelector
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                autoHeight
                                getRowId={(row) => row.subject_id || row.id}
                            />
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="primary"
                    loadingPosition="start"
                    onClick={handleClose}
                    variant="contained"
                >
                    Cerrar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SubjectModal;
