import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTotalGains } from '../../../store/home/kpis';
import { ReactComponent as MoneyBagIcon } from '../../../assets/icons/money-tgrant-success-line.svg';
import MonthlyIncome from './MonthlyIncome';
import ErrorPage from '../../../components/Error/ErrorPage';

const GainsCard = ({ statusServer, onClickRetry }) => {
    ////////////////////////// SHARE STATE //////////////////////////
    const totalGains = useSelector(selectTotalGains).value;

    return (
        <Card
            style={{
                backgroundColor: '#4169e2',
                color: 'white',
                borderRadius: '15px',
                width: '100%',
                height: '100%',
                padding: '10px',
            }}>
            <CardContent style={{ position: 'relative' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        paddingBottom: '10px',
                    }}></div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Box
                        style={{
                            backgroundColor: '#6383e6',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px',
                            paddingBottom: '10px',
                            borderRadius: '100px',
                        }}>
                        <MoneyBagIcon style={{ width: 50, height: 50 }} />
                    </Box>
                </div>
                <Typography
                    variant="h5"
                    component="div"
                    style={{ marginTop: '10px' }}>
                    Ganancias
                </Typography>
                <Typography
                    variant="h3"
                    component="div"
                    style={{ marginTop: '20px', fontWeight: 'bold' }}>
                    {totalGains}
                </Typography>

                <div
                    style={{
                        height: '500px',
                        marginTop: '20px',
                        position: 'relative',
                    }}>
                    {statusServer === 'fulfilled' && <MonthlyIncome />}
                    {statusServer === 'pending' && <CircularProgress />}

                    {statusServer === 'rejected' && (
                        <ErrorPage
                            message="No se pudo cargar la información"
                            onClickRetry={onClickRetry}
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default GainsCard;
