import React, { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useSelector } from 'react-redux';
import { ChartTooltip } from '../../../components/Tooltips/ChartTooltips';
import { selectMonthlyPaymentsCount } from '../../../store/home/tables';

const MonthlyIncome = () => {
    ////////////////////////// HOOKS //////////////////////////
    const monthlyPaymentsData = useSelector(selectMonthlyPaymentsCount);
    ////////////////////////// LOCAL STATE //////////////////////////
    const [activeBar, setActiveBar] = useState(null);

    ////////////////////////// FUNCTIONS //////////////////////////
    const maxValue = Math.max(...monthlyPaymentsData.map((item) => item.value));

    const getBarColor = (data) => {
        if (activeBar === data.label) {
            return '#FFD700';
        } else if (data.value === maxValue) {
            return activeBar ? '#f0f0f0' : '#fafafa';
        } else {
            return '#ffffff';
        }
    };

    return (
        <ResponsiveBar
            data={monthlyPaymentsData}
            keys={['value']}
            indexBy="label"
            margin={{ top: 50, bottom: 0, left: 0 }}
            padding={0.7}
            colors={({ data }) => getBarColor(data)}
            borderRadius={10}
            enableLabel={false}
            tooltip={(data) => (
                <ChartTooltip
                    id={data.id}
                    value={`$${data.value}`}
                    color={data.color}
                    indexValue={data.indexValue}
                />
            )}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            theme={{
                grid: {
                    line: {
                        stroke: '#ffffff00',
                    },
                },
            }}
            onMouseEnter={(data) => setActiveBar(data.label)}
            onMouseLeave={() => setActiveBar(null)}
            layers={[
                'grid',
                'axes',
                'bars',
                'markers',
                'legends',
                (props) => (
                    <g>
                        {props.bars.map((bar) => (
                            <text
                                key={bar.key}
                                x={bar.x + bar.width / 2}
                                y={bar.y - 10}
                                textAnchor="middle"
                                style={{
                                    fill: 'black',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                }}>
                                {bar.data.label}{' '}
                            </text>
                        ))}
                    </g>
                ),
            ]}
        />
    );
};

export default MonthlyIncome;
