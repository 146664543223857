import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import React from 'react';
import { Avatar, Col, Row } from 'antd';
import { selectLastStudentWithDiscountTable } from '../../../store/home/tables';
import femaleAvatar from '../../../assets/img/female_student.png';
import maleAvatar from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';
import { ReactComponent as CuponIcon } from '../../../assets/icons/coupon-discount-sale-svgrepo-com.svg';
import { ReactComponent as ConceptIcon } from '../../../assets/icons/credit-card-payment-card-payment-pay-with-credit-card-accept-credit-cards-svgrepo-com.svg';

export const TopStudentsDiscount = () => {
    const lastStudentWithDiscount = useSelector(
        selectLastStudentWithDiscountTable
    ).value;

    const rows = Array.isArray(lastStudentWithDiscount)
        ? lastStudentWithDiscount.slice(0, 5)
        : [];

    const getAvatarSrc = (row) => {
        if (row.avatar) {
            return row.avatar;
        }
        return row.gender === 'M'
            ? femaleAvatar
            : row.gender === 'H'
            ? maleAvatar
            : defaultStudent;
    };

    return (
        <Card
            sx={{
                height: '100%',
                borderRadius: '15px',
                width: '100%',
            }}>
            <Box
                sx={{
                    display: 'flex',
                }}>
                <Typography
                    variant="h5"
                    component="div"
                    style={{ marginTop: '20px', fontWeight: 'bold' }}>
                    Alumnos con Descuentos
                </Typography>
            </Box>
            <CardContent>
                {rows.map((row, index) => (
                    <div key={index}>
                        <Row gutter={[32, 24]} style={{ padding: '10px' }}>
                            <Col xs={24} xl={10}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <Avatar
                                        src={getAvatarSrc(row)}
                                        sx={{
                                            width: '70px',
                                            height: '70px',
                                            marginRight: '16px',
                                        }}
                                    />
                                    <Box ml={2}>
                                        <Typography
                                            variant="h7"
                                            component="div">
                                            {row.student_name}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={11} xl={6} style={{ padding: '0' }}>
                                <Box
                                    sx={{
                                        backgroundColor: '#e4f7d7',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        marginRight: '10px',
                                    }}>
                                    <CuponIcon
                                        style={{
                                            width: 30,
                                            height: 30,
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            textAlign: 'center',
                                            marginTop: '5px',
                                            color: '#55ca00',
                                            fontWeight: 'bold',
                                            fontSize: '.6rem',
                                        }}>
                                        {row.label}
                                    </Typography>
                                </Box>
                            </Col>
                            <Col
                                xs={1}
                                xl={1}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '0',
                                }}>
                                <Divider orientation="vertical" />
                            </Col>
                            <Col xs={11} xl={6} style={{ padding: '0' }}>
                                <Box
                                    sx={{
                                        backgroundColor: '#d9f4ff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        padding: '10px',
                                        borderRadius: '10px',
                                    }}>
                                    <ConceptIcon
                                        style={{
                                            width: 30,
                                            height: 30,
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            textAlign: 'center',
                                            marginTop: '5px',
                                            color: '#35bcfe',
                                            fontWeight: 'bold',
                                            fontSize: '.6rem',
                                        }}>
                                        {row.concept_title}
                                    </Typography>{' '}
                                </Box>
                            </Col>
                        </Row>
                        <Divider />
                    </div>
                ))}
            </CardContent>
        </Card>
    );
};

export default TopStudentsDiscount;
